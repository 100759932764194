import {OidcSecurityService} from "angular-auth-oidc-client";
import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {BehaviorSubject, catchError, filter, Observable, of, switchMap, take, tap, throwError} from "rxjs";
import {environment} from "../../environments/environment";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  private isRefreshingToken = new BehaviorSubject<boolean>(false);
  private isTokenAlmostExpired = new BehaviorSubject<boolean>(false);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const pubicUrlPreFix = environment.apiUrl + 'public';

    // Don't add the access token for public calls
    if (!req.url.startsWith(environment.apiUrl) || req.url.startsWith(pubicUrlPreFix)) {
      return next.handle(req);
    }
    return this.addTokenToHeader(req, next);
  }

  /**
   * Adds the Authorization header with the access token to the HTTP request.
   * Handles token refreshing.
   *
   * @param req - The HTTP request.
   * @param next - The HTTP handler.
   * @returns An observable of the HTTP events.
   */
  private addTokenToHeader(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let refreshTokenObs = of({});

    this.checkTokenExpiration();

    if (this.isTokenAlmostExpired.value && !this.isRefreshingToken.value) {
      refreshTokenObs = this.refreshToken().pipe(
        tap(() => this.isRefreshingToken.next(false))
      );
    }

    return refreshTokenObs.pipe(
      switchMap(() => this.isRefreshingToken.pipe(
        filter(isRefreshing => !isRefreshing),
        take(1),
        switchMap(() =>
          this.oidcSecurityService.getAccessToken().pipe(
            take(1),
            switchMap(token => next.handle(this.addTokenHeader(req, token)))
          )
        )
      )),
      catchError(error => this.handleHttpError(error, req, next))
    );

  }


  /**
   * Handles HTTP errors, checks for 401 status.
   *
   * @param error - The HTTP error.
   * @param req - The HTTP request.
   * @param next - The HTTP handler.
   * @returns An observable that continues the error flow or the updated HTTP request.
   */
  private handleHttpError(error: any, req: HttpRequest<any>, next: HttpHandler): Observable<never | HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        return this.handle401Error(req, next);
      }
    }
    return throwError(error);
  }

  /**
   * Handles the 401 status error by initiating token refreshing and retrying the HTTP request.
   *
   * @param request - The HTTP request.
   * @param next - The HTTP handler.
   * @returns An observable of the HTTP events.
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshToken().pipe(
      switchMap(() =>
        this.isRefreshingToken.pipe(
          take(1),
          filter(isRefreshing => !isRefreshing)
        )
      ),
      switchMap(() =>
        this.oidcSecurityService.getAccessToken().pipe(
          take(1),
          switchMap(token => next.handle(this.addTokenHeader(request, token)))
        )
      )
    );
  }


  /**
   * Refreshes the access token.
   *
   * @returns An observable that completes after the token is refreshed.
   */
  private refreshToken() {
    this.isRefreshingToken.next(true);
    // in case of error in refreshing of token, OAuthService event will handle it and redirect to login page
    return this.oidcSecurityService.forceRefreshSession()
      .pipe(
        tap(() => this.isRefreshingToken.next(false)),
        catchError(() => {
          this.isRefreshingToken.next(false);
          return this.oidcSecurityService.logoffAndRevokeTokens().pipe(
            tap(() => window.location.reload())
          )
        })
      )
  }

  /**
   * Adds the Authorization header with the access token to the HTTP request.
   *
   * @param request - The HTTP request.
   * @param accessToken - The access token.
   * @returns The updated HTTP request.
   */
  private addTokenHeader(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set("Authorization", "Bearer " + accessToken)
    });
  }

  /**
   * Checks if the stored authentication token is about to expire (within 30 seconds).
   */
  private checkTokenExpiration(): void {
    // Retrieve and parse the token expiration time from local storage.
    const tokenExpireTime: number = JSON.parse(localStorage.getItem('0-crm-web'))['access_token_expires_at'];

    // Get the current time in milliseconds.
    const currentTime: number = new Date().getTime();

    // Calculate the difference in seconds between the access token expiration time and the current time.
    // If the difference is less than or equal to 30 seconds, the token is considered almost expired.
    const isTokenAlmostExpired: boolean = ((tokenExpireTime - currentTime) / 1000) <= 30;
    this.isTokenAlmostExpired.next(isTokenAlmostExpired);
  }

}
