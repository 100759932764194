<div class="fixed top-0 left-0 z-50 h-screen w-[15%] flex flex-col gap-8 bg-neutral-0 border-r-[1px]
      border-r-neutral-300 py-6 px-4">
  <div class="pl-2.5">
    <img ngSrc="assets/logo.svg" alt="CRM Logo" height="24.49" width="120" priority>
  </div>
  <div class="w-full h-full flex flex-col justify-between">
    <div class="flex flex-col gap-2">
      <div>
        <a (click)="toggleSublist(sublist, arrowIcon)" class="flex justify-between items-center rounded-md
           hover:bg-neutral-100 p-3.5 transition-colors cursor-pointer"
        >
          <div class="flex justify-start items-center gap-3">
            <img ngSrc="assets/icons/contact.svg" alt="Contact Icon" width="22" height="22">
            <p class="text-base text-primary-900 font-medium">Contacts</p>
          </div>
          <img ngSrc="assets/icons/arrow.svg" alt="Arrow Icon" width="12" height="12" class="transition-transform
             duration-300 mr-1 mt-0.5" #arrowIcon>
        </a>
        <div class="flex flex-col gap-2 border-l-neutral-200 border-l-[1px] ml-7 pl-2 mt-2.5 overflow-hidden max-h-96
          customTransition" #sublist>
          <a class="flex justify-start items-center gap-3 p-3.5 rounded-md hover:bg-neutral-100 transition-colors
            cursor-pointer" routerLink="contacts/all" routerLinkActive="bg-neutral-100">
            <img ngSrc="assets/icons/users-2.svg" alt="Users Icon" width="18" height="18">
            <p class="text-base text-primary-900 font-medium ">All</p>
          </a>
          <a class="flex justify-start items-center gap-3 p-3.5 rounded-md hover:bg-neutral-100 transition-colors
            cursor-pointer" routerLink="contacts/lists" routerLinkActive="bg-neutral-100">
            <img ngSrc="assets/icons/list-plus.svg" alt="List Plus Icon" width="18" height="18">
            <p class="text-base text-primary-900 font-medium ">Lists</p>
          </a>
          <a class="flex justify-start items-center gap-3 p-3.5 rounded-md hover:bg-neutral-100 transition-colors
            cursor-pointer" routerLink="contacts/review" routerLinkActive="bg-neutral-100">
            <img ngSrc="assets/icons/review.svg" alt="List Plus Icon" width="16" height="13">
            <p class="text-base text-primary-900 font-medium ">Lotus import</p>
          </a>
        </div>
      </div>
      <a class="flex justify-start items-center gap-3 p-3.5  rounded-md hover:bg-neutral-100 transition-colors cursor-pointer"
        routerLink="meetings" routerLinkActive="bg-neutral-100">
        <img ngSrc="assets/icons/calendar.svg" alt="Calendar Icon" width="18" height="18">
        <p class="text-base text-primary-900 font-medium">Meetings & Events</p>
      </a>
      <a
        class="flex justify-start items-center gap-3 p-3.5  rounded-md hover:bg-neutral-100 transition-colors cursor-pointer"
        routerLink="companies" routerLinkActive="bg-neutral-100">
        <img ngSrc="assets/icons/building.svg" alt="Building Icon" width="18" height="18">
        <p class="text-base text-primary-900 font-medium">Companies</p>
      </a>
      <a
        class="flex justify-start items-center gap-3 p-3.5  rounded-md hover:bg-neutral-100 transition-colors cursor-pointer"
        routerLink="groups" routerLinkActive="bg-neutral-100">
        <img ngSrc="assets/icons/atom.svg" alt="Building Icon" width="18" height="18">
        <p class="text-base text-primary-900 font-medium">Groups</p>
      </a>
      <a
        class="flex justify-start items-center gap-3 p-3.5 rounded-md hover:bg-neutral-100 transition-colors cursor-pointer"
        routerLink="administration" routerLinkActive="bg-neutral-100">
        <img ngSrc="assets/icons/settings.svg" alt="Settings Icon" width="18" height="18">
        <p class="text-base text-primary-900 font-medium ">Administration</p>
      </a>
    </div>


    <div class="flex gap-3 items-center border-t-[1px] border-neutral-200 pt-8">
      @if (currentUser$ | async; as user) {
      <div class="bg-blue-100 flex justify-center items-center w-12 h-12 rounded-full">
        {{ user.person.initials || '-' }}
      </div>
      <div>
        <p class="text-[1.10rem] text-primary-900 font-semibold">{{ user.person.getFullName() }}</p>
        <p class="text-[1.10rem] text-neutral-700">{{ user.person.role }}</p>
      </div>
      }
      <div class="ml-auto">
        <button class="btn-ghost" pTooltip="Logout" tooltipPosition="top" [positionTop]="-5" (click)="logout()">
          <span [inlineSVG]="'assets/icons/log-out.svg'"></span>
        </button>
      </div>
    </div>
  </div>
</div>
